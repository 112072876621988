import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import { TableDisplay } from "../TableDisplay/TableDisplay";
import { APIResource } from "../../../Services/APIResource/APIResource";
import DateFormatter from "../../../Services/DateFormatter";
import Http from "../../../Services/Http";
import Alert from "../../../Services/Alert";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {FormGroup, List, ListItem, Switch} from "@material-ui/core"
import { Select } from "../../Forms/Select/Select";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { DisplayTextField } from "../DisplayTextField/DisplayTextField";
import IconButton from "@material-ui/core/IconButton";
import { ParameterSelect } from "../../Forms/ParameterSelect/ParameterSelect";
import { PARAMETER_TYPE_DOCUMENT_CATEGORY } from "../../../Admin/ParameterAdmin";
import Typography from "@material-ui/core/Typography";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { observer } from "mobx-react";
import APIResourceStore from "../../../Store/APIResourceStore";
import { ActionButton } from "../../Modal/ActionButton";
import { ButtonBar } from "../../Modal/ButtonBar";
import { DetailButton } from "../../Buttons/DetailButton";
import { EditButton } from "../../Buttons/EditButton";
import { RemoveButton } from "../../Buttons/RemoveButton";
import {OpenButton} from "../../Buttons/OpenButton";
import ParameterStore, {
    userHasRoleADMIN,
    userHasRoleIG,
    userHasRoleMRM,
    userHasSpecificRole
} from "../../../Store/ParameterStore";
import ModalProvider from "../../../Services/ModalProvider";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LaunchIcon from "@material-ui/icons/Launch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CommentIcon from "@material-ui/icons/Comment";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalDocument from "../../../Services/ModalDocument";
import { Tooltip } from '@material-ui/core';
import {ROLE} from "../../../Services/User/User";
import { DeleteDocumentForm } from "../../Document/DeleteDocumentForm";
import Checkbox from "@material-ui/core/Checkbox";
import {EntityExport} from "../../Export/EntityExport";
import {createHandlerObject} from "../../../Services/utils";
import {DownloadDocument} from "../../Export/DownloadDocument";
import ModelProvider from "../../../Services/APIResource/FieldProviders/ModelProvider";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const TYPE_FILE = 0;
export const TYPE_URL  = 1;
export const TYPE_TEXT = 2;

var typesMap = {
    [TYPE_FILE]: "File",
    [TYPE_URL]: "Url",
    [TYPE_TEXT]: "Text",
};

var allowedTypesMap = {
    file: TYPE_FILE,
    url: TYPE_URL,
    text: TYPE_TEXT,
};

export const DOCUMENT_CATEGORY_DRAFT_REPORT = 'DOCUMENT_CATEGORY_DRAFT_REPORT';
export const DOCUMENT_CATEGORY_FINAL_REPORT = 'DOCUMENT_CATEGORY_FINAL_REPORT';
export const DOCUMENT_CATEGORY_POST_COMMITTEE_REPORT = 'DOCUMENT_CATEGORY_POST_COMMITTEE_REPORT';
export const DOCUMENT_CATEGORY_MODELING_DOCUMENTATION = 'DOCUMENT_CATEGORY_MODELING_DOCUMENTATION';
export const DOCUMENT_CATEGORY_ONGOING_MONITORING_DOCUMENTATION = 'DOCUMENT_CATEGORY_ONGOING_MONITORING_DOCUMENTATION';
export const DOCUMENT_CATEGORY_RETIREMENT = 'DOCUMENT_CATEGORY_RETIREMENT';
export const DOCUMENT_CATEGORY_SPECIFIC_FRAMEWORK = 'DOCUMENT_CATEGORY_SPECIFIC_FRAMEWORK';
export const DOCUMENT_CATEGORY_MINUTES = 'DOCUMENT_CATEGORY_MINUTES';
export const DOCUMENT_CATEGORY_PRESENTATION = 'DOCUMENT_CATEGORY_PRESENTATION';


export const DOCUMENT_ACTION_SHOW = 'show';
export const DOCUMENT_ACTION_ADD = 'add';
export const DOCUMENT_ACTION_EDIT = 'edit';
export const DOCUMENT_ACTION_LIST = 'list';
export const DOCUMENT_ACTION_DELETE = 'delete';

export const DOCUMENT_BULK_DOWNLOAD = 'bulk_download';

const defaultCategoryFilter = (category) => {
    if(category.systemId === "DOCUMENT_CATEGORY_PROCEDURE") return null;
    return category;
};

const openDescription = (title, document) => {
    ModalDocument.open({
        title: title,
        content: (
            <div>
                <DisplayTextField
                    fieldName="Content"
                    value={document.description}
                />
                {document.comment ? (
                    <DisplayTextField
                        fieldName="Comment"
                        value={document.comment}
                    />
                ) : null}
            </div>
        ),
    });
};

const openComment = (title, document) => {
    ModalDocument.open({
        title: title,
        content: (
            <DisplayTextField fieldName="Comment" value={document.comment} />
        ),
    });
};

const openNetwork = (document) => {
    ModalDocument.open({
        title: "External URL",
        content: (
            <DisplayTextField fieldName="External URL" value={document.externalUrl} />
        ),
    });
};


/**
 * Utilisation du composant DocumentManager
 *
 * @param {string} label - label={'Repository link'} -> Label pour les boutons, "Add Document" devient "Add Repository link"
 * @param {string} fieldName - fieldName={'RepositoryLink'} -> Champ à utiliser pour le stockage côté back des documents "$entity->addDocument($newDocument)" devient "$entity->addRepositoryLink($newDocument)"
 * @param {string} propertyName - propertyName={'repositoryLinkEntities'} -> Champ à utiliser pour le stockage côté front des documents "entity.documentsEntities" devient "entity->repositoryLinksEntities"
 * @param {Array} values - values={value} -> Array collection des documents
 * @param {Object} entity - entity={entity} -> Entité devant stocker les documents
 * @param {Array} allowedTypes - allowedTypes={['url']} -> Types de document autorisés, prend les clés du tableau js allowedTypesMap
 * @param {Array} fileTypes - fileTypes={['pdf']} -> Tableau des extensions autorisées
 * @param {number} limit - limit={1} -> Limit le nombre de document possible
 */
export const DocumentManager = observer(
    class DocumentManager extends Component {
        constructor(props) {
            super(props);

            this.addDocument = this.addDocument.bind(this);
            this.deleteDocument = this.deleteDocument.bind(this);

            this.state = {
                values: null,
                tableValues: [],
                fileTypes: null,
                showAddButton: false,
                checked: [],
                bulkActions: [],
            };
            this.resource = new APIResource({ id: "documents" });
            if (this.props.entityResource) {
                this.entityResource =
                    APIResourceStore.resources[this.props.entityResource];
            }
        }

        componentDidMount() {
            this.valueInit();
            this.allowToAddDocument();
            this.allowToEditDocument();
            this.populateBulkActions();
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (this.props.values !== prevProps.values) {
                this.valueInit();
                this.allowToAddDocument();
                this.allowToEditDocument();
            }
            if (this.props.bulkActions !== prevProps.bulkActions) {
                this.populateBulkActions();
            }
        }

        getLabel(ucFirst = false) {
            let label = this.props.label
                ? this.props.label.toLowerCase()
                : "document";
            if (ucFirst === true) {
                return label[0].toUpperCase() + label.substring(1);
            }
            return label;
        }

        getFieldName() {
            return this.props.fieldName ? this.props.fieldName : "Document";
        }

        getPropertyName() {
            return this.props.propertyName
                ? this.props.propertyName
                : "documentsEntities";
        }

        valueInit(entity = null) {
            if (entity !== null) {
                this.setState({ tableValues: entity[this.getPropertyName()] });
            } else if (Array.isArray(this.props.values)) {
                this.setState({ tableValues: this.props.values });
            } else {
                this.setState({ tableValues: [] });
            }
            if (this.props.fileTypes) {
                this.setState({ fileTypes: this.props.fileTypes });
            }
        }

        populateBulkActions() {
            const bulkActions = [];
            if(Array.isArray(this.props.bulkActions) && this.props.bulkActions.length > 0){
                this.props.bulkActions.forEach((bulk) => {
                    switch(bulk){
                        case DOCUMENT_BULK_DOWNLOAD:
                            bulkActions.push(createHandlerObject(DownloadDocument, {
                                resource: "documents",
                                label: "Download document(s)"
                            }));
                            break;
                    }
                })
            }
            this.setState({bulkActions});
        }

        allowToAddDocument() {
            let allowToAdd =
                this.props.allowedAction !== undefined
                    ? this.props.allowedAction(this.props.entity, {}, DOCUMENT_ACTION_ADD)
                    : true;
            if (
                (this.props.limit === undefined ||
                    (Array.isArray(this.props.values) &&
                        this.props.values.length < this.props.limit) ||
                    (Array.isArray(this.props.values) === false &&
                        ((this.props.limit !== undefined &&
                            this.props.limit > 0) ||
                            this.props.limit === undefined))) &&
                allowToAdd
            ) {
                this.setState({ showAddButton: true });
            } else {
                this.setState({ showAddButton: false });
            }
        }

        allowToEditDocument() {
            let allowToEdit =
                this.props.allowedAction !== undefined
                    ? this.props.allowedAction(this.props.entity, {}, DOCUMENT_ACTION_EDIT)
                    : true;
            this.setState({ showEditButton: allowToEdit });
        }

        addDocument() {
            ModalDocument.open({
                title: "New " + this.getLabel(),
                content: (
                    <DocumentAddForm
                        fileTypes={this.state.fileTypes}
                        onSave={(formState, callback) =>
                            this.onSave(formState, callback)
                        }
                        allowedTypes={this.props.allowedTypes}
                        allowedCategory={this.props.allowedCategory}
                        categoryFilter={this.props.categoryFilter ?? defaultCategoryFilter}
                        categoryParameterSystemId={this.props.categoryParameterSystemId}
                        allowedAction={this.props.allowedAction}
                        allowedSelectModels={this.props.allowedSelectModels}
                        entity={this.props.entity}
                        defaultValues={this.props.defaultValues}
                    />
                ),
            });
        }

        editDocument(document) {
            ModalDocument.open({
                title: "Edit " + this.getLabel(),
                content: (
                    <DocumentAddForm
                        document={document}
                        fileTypes={this.state.fileTypes}
                        onSave={(formState, callback) =>
                            this.onSave(formState, callback)
                        }
                        allowedTypes={this.props.allowedTypes}
                        allowedCategory={this.props.allowedCategory}
                        categoryFilter={this.props.categoryFilter ?? defaultCategoryFilter}
                        categoryParameterSystemId={this.props.categoryParameterSystemId}
                        allowedAction={this.props.allowedAction}
                        allowedSelectModels={this.props.allowedSelectModels}
                        entity={this.props.entity}
                        defaultValues={this.props.defaultValues}
                    />
                ),
            });
        }

        uploadDocument(document, callback = null) {
            let self = this;
            let method = 'apiPostFile';
            if(document.id){
                method = 'apiPut';
            }
            return self.resource[method]
                ({
                    id: document.id,
                    "@type": 'Document',
                    title: document.title,
                    type: document.type,
                    category: document.category,
                    file: document.documentFile,
                    externalUrl: document.externalUrl,
                    description: document.description,
                    comment: document.comment,
                    entity: {
                        id: self.props.entity.id,
                        "@type": self.props.entity["@type"],
                    },
                    fieldName: self.getFieldName(),
                    ...(userHasRoleMRM() ? {mrmCompliance: this.state.mrmCompliance} : {}),
                    ...(this.props.allowedSelectModels && document.models ? {models: document.models} : {}),
                })
                .catch(() => {
                    if (callback) {
                        callback(true);
                    }
                });
        }

        async onSave(formState, callback = null) {
            let values = this.state.tableValues;

            if (!formState.id && formState.type === TYPE_FILE) {
                if (!formState.documents?.length) return;
                const responses = await Promise.all(
                    formState.documents.map((d) => this.uploadDocument(
                        {
                            ...formState,
                            documentFile: d.documentFile,
                            title: formState.documents.length > 1 ? d.title : formState.title || d.title
                        },
                        callback
                    ))
                );
                responses.map((response) => {
                    response.dateString = response?.date ? DateFormatter.dayHourIsoToString(response.date) : null;
                    values.push(response);
                });
            } else {
                const response = await this.uploadDocument(
                    {...formState, documentFile: formState.documents},
                    callback
                );
                response.dateString = response?.date ? DateFormatter.dayHourIsoToString(response.date) : null;
                values.push(response);
            }
            this.updateEntity(values);
            if (callback) {
                callback();
            }
            ModalDocument.close();
        }

        deleteDocument(document) {
            ModalDocument.open({
                title: "Delete this document",
                content: <DeleteDocumentForm
                        document={document}
                        resource={this.resource}
                        callback={() => {
                            Alert.show({
                                message: this.getLabel(true) + " deleted",
                                type: "success",
                            });
                            let values = this.state.tableValues.filter((value) => value.id !== document.id);
                            this.updateEntity(values);
                            ModalDocument.close();
                        }}
                    />,
                size: "small",
            })
        }

        updateEntity(documents) {
            this.props.entity[this.getPropertyName()] = documents;
            this.props.entity[this.getFieldName().toLowerCase() + 's'] = (documents || []).map(d => `/api/documents/${d.id}`);
            if(this.props.preUpdate){
                this.props.preUpdate();
            }
            if (this.entityResource) {
                //Force updating parent from API when changing Documents collection are affecting parent's properties
                this.entityResource
                    .getItemFromResourcePath(this.props.entity["@id"], true)
                    .then((entity) => {
                        this.valueInit(entity);
                        if (this.props.onUpdate) {
                            this.props.onUpdate(entity);
                        }
                    });
            } else {
                this.valueInit(this.props.entity);
                if (this.props.onUpdate) {
                    this.props.onUpdate(this.props.entity);
                }
            }
        }

        render() {
            let cols = [
                { label: "Title", field: "title" },
                { label: "Type", field: "typeString" },
                { label: "Date", field: "dateString" },
                { label: "Author", field: "authorString" },
            ];
            if (this.props.allowedCategory) {
                cols = [
                    { label: "Title", field: "title" },
                    { label: "Category", field: "categoryString" },
                    { label: "Type", field: "typeString" },
                    { label: "Date", field: "dateString" },
                    { label: "Author", field: "authorString" },
                ];
            }
            const allowToAdd =
                this.props.allowedAction !== undefined
                    ? this.props.allowedAction(this.props.entity, null, DOCUMENT_ACTION_ADD)
                    : true;

            return (
                <div>
                    {(typeof this.props.onlyButton !== "undefined" ||
                        this.props.onlyButton === true) &&
                    allowToAdd ? (
                        <Button
                            key={"button_add_document"}
                            style={{ zIndex: 0 }}
                            variant="contained"
                            color="primary"
                            className="button-general"
                            onClick={this.addDocument}
                        >
                            <Icon
                                className={"fa " + (this.props.addButton && this.props.addButton.icon ? this.props.addButton.icon : "fa-plus")}
                                style={{color: this.props.addButton && this.props.addButton.color ? this.props.addButton.color : ''}}
                            ></Icon>
                            {"New " + this.getLabel()}
                        </Button>
                    ) : (
                        <TableDisplay
                            rows={this.state.tableValues.filter((d) => {
                                    let show = this.props.allowedAction !== undefined
                                        ? (this.props.allowedAction(
                                            this.props.entity,
                                            d,
                                            DOCUMENT_ACTION_SHOW
                                        ) || userHasSpecificRole(ROLE.IG))
                                        : true;
                                    let list = this.props.allowedAction !== undefined
                                        ? (this.props.allowedAction(
                                            this.props.entity,
                                            d,
                                            DOCUMENT_ACTION_LIST
                                        ))
                                        : true;
                                    return show && (list || d.context === 'NEW_DOCUMENT')
                                }
                            )}
                            cols={cols}
                            bulkActions={this.state.bulkActions}
                            onSelectionChange={this.props.onSelectionChange}
                            hideIfEmpty={true}
                            actions={(document) => {
                                const allowToShow =
                                    this.props.allowedAction !== undefined
                                        ? (this.props.allowedAction(
                                              this.props.entity,
                                              document,
                                              DOCUMENT_ACTION_SHOW
                                          ) || userHasSpecificRole(ROLE.IG))
                                        : true;
                                const allowToEdit =
                                    this.props.allowedAction !== undefined
                                        ? this.props.allowedAction(
                                              this.props.entity,
                                              document,
                                              DOCUMENT_ACTION_EDIT
                                          )
                                        : false;
                                const allowToDelete =
                                    this.props.allowedAction !== undefined
                                        ? this.props.allowedAction(
                                              this.props.entity,
                                              document,
                                              DOCUMENT_ACTION_DELETE
                                          )
                                        : true;
                                return (
                                    <div>
                                        {document.type === 0 && allowToShow ? (
                                            <a
                                                onClick={() =>
                                                    Http.openEncryptedFile(
                                                        "/document/download/" +
                                                            document.id,
                                                        document.documentName
                                                    )
                                                }
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="button-table show"
                                                >
                                                    Download
                                                </Button>
                                            </a>
                                        ) : null}
                                        {document.type === 1 && !document.network && allowToShow ? (
                                            <a
                                                href={document.externalUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <DetailButton>
                                                    Open
                                                </DetailButton>
                                            </a>
                                        ) : null}
                                        {document.type === 1 && document.network && allowToShow ? (
                                            <OpenButton
                                                onClick={() =>
                                                    openNetwork(
                                                        document
                                                    )
                                                }
                                            />
                                        ) : null}
                                        {document.type === 2 && allowToShow ? (
                                            <DetailButton
                                                onClick={() =>
                                                    openDescription(
                                                        this.getLabel(true) +
                                                            " : " +
                                                            document.title,
                                                        document
                                                    )
                                                }
                                            />
                                        ) : null}
                                        {document.type !== 2 &&
                                        document.comment &&
                                        allowToShow ? (
                                            <DetailButton
                                                onClick={() =>
                                                    openComment(
                                                        this.getLabel(true) +
                                                            " : " +
                                                            document.title,
                                                        document
                                                    )
                                                }
                                            >
                                                Comment
                                            </DetailButton>
                                        ) : null}
                                        {allowToEdit ? (
                                            <EditButton
                                                onClick={() =>
                                                    this.editDocument(
                                                        document
                                                    )
                                                }
                                            >
                                                Edit
                                            </EditButton>
                                        ) : null}
                                        {allowToDelete ? (
                                            <RemoveButton
                                                onClick={() =>
                                                    this.deleteDocument(
                                                        document
                                                    )
                                                }
                                            >
                                                Remove
                                            </RemoveButton>
                                        ) : null}
                                    </div>
                                );
                            }}
                            buttons={
                                this.state.showAddButton && (this.props.showAddButton === undefined || this.props.showAddButton === true)
                                    ? [
                                          {
                                              label: "New " + this.getLabel(),
                                              onClick: () => this.addDocument(),
                                              icon: "fa-plus",
                                          },
                                      ]
                                    : null
                            }
                        />
                    )}
                </div>
            );
        }
    }
);
/*
DocumentManager.propTypes = {
  label: PropTypes.string,
  fieldName: PropTypes.string,
  propertyName: PropTypes.string,
  values: PropTypes.array,
  entity: PropTypes.object,
  allowedCategory: PropTypes.bool,
  allowedTypes: PropTypes.array,
  fileTypes: PropTypes.array,
  limit: PropTypes.number,
}; */

/**
 *
 */
export class DocumentAddForm extends Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
        this.typeChange = this.typeChange.bind(this);
        this.state = {
            id: null,
            title: null,
             /** @type {Array<{title: , documentFile: }>} */
            documents: null,
            type: null,
            category: null,
            externalUrl: null,
            description: null,
            comment: null,
            mrmCompliance: null,
            dropClass: "",
            document: null,
            isSaving: false,
            allowToAdd: true,
            messageError: null,
            maxSize: null,
            sizeReadable: null,
            disabledType: false,
            models: [],
            editMode: false
        };

        this.allowedTypes = [];
    }

    componentDidMount() {
        this.allowedTypes = this.getAllowedTypesMap();
        if(this.props.allowedCategory && this.props.defaultValues){
            if("category" in this.props.defaultValues){
                this.setState({category: this.props.defaultValues.category ?? null})
            }else if("categorySystemId" in this.props.defaultValues){
                let category = ParameterStore(this.props.defaultValues.categorySystemId);
                this.setState({category: category})
            }
        }

        if(this.props.document){
            this.setState({
                id: this.props.document.id,
                title: this.props.document.title,
                type: this.props.document.type,
                category: this.props.document.category,
                externalUrl: this.props.document.externalUrl,
                description: this.props.document.description,
                comment: this.props.document.comment,
                models: this.props.document.models,
                mrmCompliance: this.props.document.mrmCompliance,
                editMode: true
            });
        }

        if (this.props.entity?.['@type'] === 'Model') this.setState({models: [this.props.entity?.['@id']]});
        this.getMaxSize().then();
    }

    async getMaxSize() {
        const response = await Http.get("document/size", null);
        this.setState({maxSize: response.size, maxSizeReadable: response.sizeReadable});
    }

    checkRights() {
        let allowToAdd =
            this.props.allowedAction !== undefined
                ? this.props.allowedAction(this.props.entity, this.state, DOCUMENT_ACTION_ADD)
                : true;
        if (allowToAdd === true) {
            this.setState({ allowToAdd: true, messageError: null });
        } else if (allowToAdd === false) {
            this.setState({ allowToAdd: false, messageError: null });
        } else if (typeof allowToAdd === "string") {
            this.setState({ allowToAdd: false, messageError: allowToAdd });
        }
    }

    onDrop(acceptedFiles) {
        this.setState({ dropClass: "", disabledType: true });
        if (Array.isArray(acceptedFiles)) {
            if (acceptedFiles.length > 0) {
                const documents = [];
                for (let document of acceptedFiles){
                    const fileType = document.name.split(".").pop();
                    if (
                        this.props.fileTypes === null ||
                        this.props.fileTypes.includes(fileType)
                    ) {
                        if(
                            this.state.maxSize === null ||
                            this.state.maxSize > document.size
                        ){
                            documents.push({
                                title: document.name,
                                documentFile: document
                            });
                        }else{
                            Alert.show({
                                message: acceptedFiles.length > 1 ? `One or more files exceed the allowed limit` : `The size of this file exceeds the allowed limit`,
                                type: "error",
                            });
                            this.checkRights();
                        }
                    } else {
                        Alert.show({
                            message: `This file type (${fileType}) is not allowed`,
                            type: "error",
                        });
                        this.checkRights();
                    }
                }
                this.setState({ documents }, () => this.checkRights());
                
            } else {
                this.checkRights();
            }
        } else {
            this.checkRights();
        }
    }

    updateCategory(category) {
        this.setState({ category: category }, () => this.checkRights());
    }

    typeChange(type) {
        switch (type) {
            case TYPE_FILE:
                this.setState(
                    { type: type, externalUrl: null, description: null },
                    () => this.checkRights()
                );
                break;
            case TYPE_URL:
                this.setState(
                    { type: type, documents: null, description: null },
                    () => this.checkRights()
                );
                break;
            case TYPE_TEXT:
                this.setState(
                    {
                        type: type,
                        documents: null,
                        externalUrl: null,
                        comment: null,
                    },
                    () => this.checkRights()
                );
                break;
        }
    }

    getAllowedTypesMap() {
        let allowedTypes = [];
        if (Array.isArray(this.props.allowedTypes)) {
            this.props.allowedTypes.forEach((type, index) => {
                if (this.state.type === null) {
                    this.setState({ type: allowedTypesMap[type] });
                }
                allowedTypes[allowedTypesMap[type]] =
                    typesMap[allowedTypesMap[type]];
            });
            return allowedTypes;
        } else {
            if (this.state.type === null) {
                this.setState({ type: 0 });
                this.checkRights();
            }
            return typesMap;
        }
    }

    save() {
        if (!this.state.editMode && this.state.type === TYPE_FILE && this.state.documents === null) {
            Alert.show({
                message: "Please drop or select a file",
                type: "error",
            });
            return;
        }
        if (!this.state.editMode && this.state.type === TYPE_URL && this.state.externalUrl === null) {
            Alert.show({
                message: "Please fill an external url",
                type: "error",
            });
            return;
        }
        if (!this.state.editMode && this.state.type === TYPE_TEXT && this.state.description === null) {
            Alert.show({ message: "Please fill the content", type: "error" });
            return;
        }

        this.setState({ isSaving: true });

        this.props.onSave(
            {
                id: this.state.id,
                title: this.state.title,
                type: this.state.type,
                category: this.state.category,
                documents: this.state.documents,
                externalUrl: this.state.externalUrl,
                description: this.state.description,
                comment: this.state.comment,
                ...(userHasRoleMRM() ? {mrmCompliance: this.state.mrmCompliance} : {}),
                ...(this.props.allowedSelectModels ? {models: this.state.models} : {}),
            },
            (error) => {
                this.setState({ isSaving: false });
            }
        );
    }

    formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    render() {
        return (
            <div className="document-modal-add">
                {this.state.messageError !== null ? (
                    <div className={"alert alert-danger"} role="alert">
                        {this.state.messageError}
                    </div>
                ) : null}
                <div className="fields">
                    {this.state.documents?.length > 1 || <FormControl>
                        <TextField
                            label="Title"
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                            value={this.state.title ? this.state.title : ""}
                        />
                    </FormControl>}
                    {!this.state.editMode && (
                    <FormControl>
                        <Select
                            label="Type"
                            disabled={this.state.disabledType}
                            options={Object.keys(this.allowedTypes).map(
                                (key) => {
                                    return {
                                        value: parseInt(key),
                                        label: this.allowedTypes[key],
                                    };
                                }
                            )}
                            value={{
                                value: this.state.type,
                                label: this.allowedTypes[this.state.type],
                            }}
                            onChange={(item) => {
                                this.typeChange(item.value);
                            }}
                        />
                    </FormControl>)}
                    {userHasRoleMRM() &&
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={!!this.state.mrmCompliance}
                                    onChange={(event, val) => {
                                        this.setState({mrmCompliance: val});
                                    }}
                                    name="document-mrm-compliance"
                                    color="primary"
                                />
                            }
                            label="MRM Compliance"
                        />
                    }

                    {(this.props.allowedCategory || (this.state.editMode && (userHasRoleMRM() || userHasRoleADMIN()))) && !this.props.hideCategory ? (
                        <FormControl>
                            <ParameterSelect
                                label="Category"
                                field={{
                                    params: {
                                        type: this.props.categoryParameterSystemId ?? PARAMETER_TYPE_DOCUMENT_CATEGORY,
                                        filters: this.props.categoryFilter ?? defaultCategoryFilter
                                    },
                                }}
                                onChange={(value) => this.updateCategory(value)}
                                required={false}
                                clearable={true}
                                value={this.state.category}
                                entity={this.props.entity}
                            />
                        </FormControl>
                    ) : null}
                    {this.props.allowedSelectModels && <FormControl>{
                        ModelProvider.getEdit(
                            {
                                title: 'Models',
                                params: {
                                    multi: true,
                                    instanceId: 'allModels',
                                    displayField: 'toString',
                                }
                            },
                            this.state.models,
                            (models) => this.setState({models}),
                            null,
                        )}
                    </FormControl>}
                    {this.state.type === 0 && !this.state.editMode && (
                        <FormControl>
                            <Dropzone
                                onDrop={(acceptedFiles) =>
                                    this.onDrop(acceptedFiles)
                                }
                                onDragEnter={() =>
                                    this.setState({ dropClass: "dz-enter" })
                                }
                                onDragLeave={() =>
                                    this.setState({ dropClass: "" })
                                }
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section
                                        className={
                                            "dropzone " + this.state.dropClass
                                        }
                                    >
                                        <div {...getRootProps()}>
                                            <input
                                                {...getInputProps()}
                                                multiple={this.props.multiple ?? true}
                                                size={this.state.maxSize}
                                            />
                                            {this.state.documents ===
                                            null || (Array.isArray(this.state.documents) && this.state.documents.length === 0) ? (
                                                <p>
                                                    Drag &apos;n&apos; drop files here, or
                                                    click to select files
                                                </p>
                                            ) : <>
                                                <IconButton>
                                                    <i
                                                        className={
                                                            "fas fa-check"
                                                        }
                                                    ></i>
                                                </IconButton>
                                                <List>
                                                    {this.state.documents.map((d, i) => {
                                                        return <ListItem key={`dropzone_${i}`}>{d.documentFile.name + `(${this.formatBytes(d.documentFile.size)})`}</ListItem>
                                                    }

                                                    )}
                                                </List>
                                            </>}
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            {this.props.fileTypes !== null ? (
                                <p>
                                    Allowed file type
                                    {this.props.fileTypes.length > 1
                                        ? "s"
                                        : ""}{" "}
                                    : {this.props.fileTypes.join(", ")}
                                </p>
                            ) : null}
                            {this.state.maxSizeReadable !== null ? (
                                <p>
                                    Max allowed size per file: {this.state.maxSizeReadable}
                                </p>
                            ) : null}
                        </FormControl>
                    )}
                    {this.state.type === 1 && !this.state.editMode && (
                        <FormControl>
                            <TextField
                                label="External URL"
                                onChange={(event) =>
                                    this.setState({
                                        externalUrl: event.target.value,
                                    })
                                }
                                value={
                                    this.state.externalUrl
                                        ? this.state.externalUrl
                                        : ""
                                }
                            />
                        </FormControl>
                    )}
                    {this.state.type === 2 && !this.state.editMode && (
                        <FormControl className="container_textarea">
                            <label>Content</label>
                            <TextareaAutosize
                                value={
                                    this.state.description
                                        ? this.state.description
                                        : ""
                                }
                                onChange={(event) =>
                                    this.setState({
                                        description: event.target.value,
                                    })
                                }
                                rows={5}
                                rowsMax={10}
                            />
                        </FormControl>
                    )}
                    {!(this.state.type === 2) && !this.state.editMode && (
                        <FormControl className="container_textarea">
                            <label>Comment</label>
                            <TextareaAutosize
                                value={
                                    this.state.comment ? this.state.comment : ""
                                }
                                onChange={(event) =>
                                    this.setState({
                                        comment: event.target.value,
                                    })
                                }
                                rows={5}
                                rowsMax={10}
                            />
                        </FormControl>
                    )}
                </div>
                <ButtonBar>
                    <ActionButton
                        color={"secondary"}
                        onClick={() => ModalDocument.close()}
                    >
                        {"Cancel"}
                    </ActionButton>
                    <ActionButton
                        color={this.props.saveButtonColor || "secondary"}
                        onClick={this.save.bind(this)}
                        disabled={this.state.allowToAdd === false || this.state.isSaving === true}
                        loading={this.state.isSaving}
                    >
                        {this.props.saveButtonLabel || "Save"}
                    </ActionButton>
                </ButtonBar>
            </div>
        );
    }
}
DocumentAddForm.propTypes = {
    fileTypes: PropTypes.array,
    /* (document, callback) => this.onSave(document, callback) */
    onSave: PropTypes.func,
    allowedTypes: PropTypes.array,
    allowedCategory: PropTypes.bool,
    /** this.props.allowedAction(this.props.entity, this.state, DOCUMENT_ACTION_ADD) */
    allowedAction: PropTypes.func,
    entity: PropTypes.object,
    saveButtonLabel: PropTypes.string,
    saveButtonColor: PropTypes.string,
};

export class DocumentList extends Component {
    renderItem() {
        let items = [];
        for (let i in this.props.values) {
            let document = this.props.values[i];
            let showLinks =
                this.props.links === undefined ? true : this.props.links;
            items.push(
                <div key={"document_" + document.id}>
                    {showLinks || <>- {document.title}</>}
                    {showLinks && document.type === 0 ? (
                        <a
                            onClick={() =>
                                Http.openEncryptedFile(
                                    "/document/download/" + document.id,
                                    document.documentName
                                )
                            }
                            style={{ cursor: "pointer" }}
                        >
                            {document.title}
                        </a>
                    ) : null}
                    {showLinks && document.type === 1 ? (
                        <a
                            href={document.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {document.title}
                        </a>
                    ) : null}
                    {showLinks && document.type === 2 ? (
                        <a
                            onClick={() =>
                                openDescription(document.title, document)
                            }
                            style={{ cursor: "pointer" }}
                        >
                            {document.title}
                        </a>
                    ) : null}
                </div>
            );
        }
        return items;
    }
    render() {
        return <div>{this.renderItem()}</div>;
    }
}
DocumentList.propTypes = {
    values: PropTypes.array,
    links: PropTypes.bool,
};

export class DocumentListAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialRows: null,
            rows: null,
            renderRows: null,
        };

        this.resource = new APIResource({ id: "documents" });
    }

    componentDidMount() {
        this.initDocuments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.rows !== this.state.rows) {
            this.renderItem();
        }
    }

    initDocuments() {
        let items = [];
        let promises = [];
        for (let i in this.props.values) {
            let document = this.props.values[i];
            if (typeof document === "string") {
                let promise = this.resource
                    .getItemFromResourcePath(document)
                    .then((entity) => {
                        items.push(entity);
                    });
                promises.push(promise);
            }
        }
        Promise.all(promises).then(() => {
            this.setState({ initialRows: items });
            this.filter("");
            this.renderItem();
        });
    }

    filter(value) {
        if (value === "") {
            this.setState({ rows: this.state.initialRows });
            return;
        }
        let filtersRows = [];
        let regex = new RegExp(value, "i");
        for (let i in this.state.initialRows) {
            if (
                regex.test(this.state.initialRows[i].dateString) ||
                regex.test(this.state.initialRows[i].title) ||
                regex.test(this.state.initialRows[i].authorString)
            ) {
                filtersRows.push(this.state.initialRows[i]);
            }
        }
        this.setState({ rows: filtersRows });
    }

    getFilter() {
        if (this.props.filter == undefined || this.props.filter === true) {
            return (
                <FormControl className={"td_filter_control"}>
                    <TextField
                        className={"td_filter_input"}
                        placeholder="Filter"
                        onChange={(event) => this.filter(event.target.value)}
                    />
                </FormControl>
            );
        }
    }

    formatDocument(document) {
        return (
            <tr key={"document_" + document.id}>
                {!this.props.hideDate ?
                    <td className={"document-date"}>{document.dateString}</td>
                : null}
                <td className={"document-content"}>
                    {document.type === 0 ? (
                        <a
                            onClick={() =>
                                Http.openEncryptedFile(
                                    "/document/download/" + document.id,
                                    document.documentName
                                )
                            }
                            style={{ cursor: "pointer" }}
                        >
                            {document.title}
                        </a>
                    ) : null}
                    {document.type === 1 ? (
                        <a href={document.externalUrl} target="_blank" rel="noreferrer">
                            {document.title}
                        </a>
                    ) : null}
                    {document.type === 2 ? (
                        <a
                            onClick={() =>
                                openDescription(document.title, document)
                            }
                            style={{ cursor: "pointer" }}
                        >
                            {document.title}
                        </a>
                    ) : null}
                </td>
                <td className={"document-author"}>{document.authorString}</td>
            </tr>
        );
    }

    renderItem() {
        let items = [];
        for (let i in this.state.rows) {
            items.push(this.formatDocument(this.state.rows[i]));
        }
        this.setState({ renderRows: items });
    }
    render() {
        return (
            <div className={"document-accordion"}>
                {this.props.values.length > 0 ? (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>View documents</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            className={"document-accordion-content"}
                        >
                            {this.getFilter()}
                            <table>
                                <thead>
                                    <tr>
                                        {!this.props.hideDate ?
                                            <th className={"date"}>Date</th>
                                        : null}
                                        <th className={"document"}>Document</th>
                                        <th className={"author"}>Author</th>
                                    </tr>
                                </thead>
                                <tbody>{this.state.renderRows}</tbody>
                            </table>
                        </AccordionDetails>
                    </Accordion>
                ) : null}
            </div>
        );
    }
}
DocumentListAccordion.propTypes = {
    values: PropTypes.array,
    entity: PropTypes.object,
    filter: PropTypes.bool,
}

const ACTION_DOWNLOAD = 'ACTION_DOWNLOAD';
const ACTION_OPEN = 'ACTION_OPEN';
const ACTION_DETAIL = 'ACTION_DETAIL';
const ACTION_COMMENT = 'ACTION_COMMENT';
const ACTION_EDIT = 'ACTION_EDIT';
const ACTION_REMOVE = 'ACTION_REMOVE';

export class DocumentButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            document: {
                title: null,
                mrmCompliance: null,
            },
        };
        this.resource = new APIResource({ id: "documents" });
    }

    componentDidMount() {
        this.getDocument();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.document !== this.props.document) {
            this.getDocument();
        }
    }

    getDocument() {
        this.setState({ document: this.props.document });
    }

    getLabel(ucFirst = false) {
        let label = this.props.label
            ? this.props.label.toLowerCase()
            : "document";
        if (ucFirst === true) {
            return label[0].toUpperCase() + label.substring(1);
        }
        return label;
    }

    deleteDocument(document) {
        ModalDocument.open({
            title: "Delete this document",
            content: <DeleteDocumentForm
                    document={document}
                    resource={this.resource}
                    callback={() => {
                        Alert.show({
                            message: this.getLabel(true) + " deleted",
                            type: "success",
                        });
                        if(this.props.callback){
                            this.props.callback();
                        }
                        ModalDocument.close();
                    }}
                />,
            size: "small",
        })
    }

    editDocument(document) {
        ModalDocument.open({
            title: "Edit this document",
            content: <DocumentAddForm
                    document={document}
                    resource={this.resource}
                    onSave={(formState, callback) =>
                        this.onSave(formState, callback)
                    }
                    entity={this.props.entity}
                />,
            size: "small",
        })
    }

    uploadDocument(document, callback){
        return this.resource
            .apiPut({
                "@id": "/api/documents/" + document.id,
                id: document.id,
                title: document.title,
                ...(userHasRoleMRM() ? {category: document.category} : {}),
                ...(userHasRoleMRM() ? {mrmCompliance: document.mrmCompliance} : {}),
            })
            .catch(() => {
                if (callback) {
                    callback(true);
                }
            });
    }

    async onSave(formState, callback){
        console.log('on save document', formState)
        const response = await this.uploadDocument(
            {...formState, documentFile: formState.documents},
            callback
        );
        if(this.props.callback){
            this.props.callback();
        }
        if (callback) {
            callback();
        }
        ModalDocument.close();
    }

    renderAction(action, onClick = null){
        let icon = null;
        let label = null;

        if(action === ACTION_DOWNLOAD){
            icon = <CloudDownloadIcon style={styles.icon} />
            label = "Download"
        }else if(action === ACTION_OPEN){
            icon = <LaunchIcon style={styles.icon} />
            label = "Open"
        }else if(action === ACTION_DETAIL){
            icon = <VisibilityIcon style={styles.icon} />
            label = "Detail"
        }else if(action === ACTION_COMMENT){
            icon = <CommentIcon style={styles.icon} />
            label = "Comment"
        }else if(action === ACTION_EDIT){
            icon = <EditIcon style={styles.icon} />
            label = "Edit"
        }else if(action === ACTION_REMOVE){
            icon = <DeleteIcon style={styles.icon} />
            label = "Remove"
        }

        let buttonProps = {};

        if(this.props.flat) {
            buttonProps = {
                style: {padding: 0},
                color: "secondary",
                className: "flat-button",
            }
        }else{
            buttonProps = {
                variant: "contained",
                color: "primary",
                className: "button-table show",
            }
        }

        if(typeof onClick === 'function'){
            buttonProps.onClick = onClick;
        }

        return ( this.props.flat ?
                    <IconButton
                        {...buttonProps}
                    >
                        { this.props.small ? icon : label}
                    </IconButton>
                    :
                    <Button
                        {...buttonProps}
                    >
                        { this.props.small ? icon : label}
                    </Button>
                )


    }

    render() {
        const allowToShow =
            this.props.allowedAction !== undefined
                ? (this.props.allowedAction(
                      this.props.entity,
                      this.props.document,
                      DOCUMENT_ACTION_SHOW
                  ) || userHasSpecificRole(ROLE.IG))
                : true;
        const allowToEdit =
            this.props.allowedAction !== undefined
                ? (this.props.allowedAction(
                      this.props.entity,
                      this.props.document,
                      DOCUMENT_ACTION_EDIT
                  ))
                : true;
        const allowToDelete =
            this.props.allowedAction !== undefined
                ? this.props.allowedAction(
                      this.props.entity,
                      this.props.document,
                      DOCUMENT_ACTION_DELETE
                  )
                : true;
        return (
            <div className={"document-actions"}>
                {this.state.document.type === 0 && allowToShow ? (
                    <a
                        onClick={() =>
                            Http.openEncryptedFile(
                                "/document/download/" + this.state.document.id,
                                this.state.document.documentName
                            )
                        }
                    >
                        {this.props.showTooltip ?
                            <Tooltip title={this.state.document.title ?? this.state.document.documentName} arrow placement="top">
                                {this.renderAction(ACTION_DOWNLOAD)}
                            </Tooltip>
                            : this.renderAction(ACTION_DOWNLOAD)
                        }
                    </a>
                ) : null}
                {this.state.document.type === 1 && !this.state.document.network && allowToShow ? (
                    <a href={this.state.document.externalUrl} target="_blank" rel="noreferrer">
                        {this.props.showTooltip ?
                            <Tooltip title={this.state.document.title} arrow placement="top">
                                {this.renderAction(ACTION_OPEN)}
                            </Tooltip>
                            : this.renderAction(ACTION_OPEN)
                        }
                    </a>
                ) : null}
                {this.state.document.type === 1 && this.state.document.network && allowToShow ? (
                    <>
                        {this.props.showTooltip ?
                            <Tooltip title={this.state.document.title} arrow placement="top">
                                {this.renderAction(ACTION_OPEN, () =>
                                    openNetwork(
                                        this.state.document
                                    )
                                )}
                            </Tooltip>
                            : this.renderAction(ACTION_OPEN, () =>
                                openNetwork(
                                    this.state.document
                                )
                            )
                        }
                    </>
                ) : null}
                {this.state.document.type === 2 && allowToShow ? (
                    <>
                        {this.props.showTooltip ?
                            <Tooltip title={this.state.document.title} arrow placement="top">
                                {this.renderAction(ACTION_DETAIL, () =>
                                    openDescription(
                                        this.getLabel(true) +
                                        " : " +
                                        this.state.document.title,
                                        this.state.document
                                    )
                                )}
                            </Tooltip>
                            : this.renderAction(ACTION_DETAIL, () =>
                                openDescription(
                                    this.getLabel(true) +
                                    " : " +
                                    this.state.document.title,
                                    this.state.document
                                )
                            )
                        }
                    </>
                ) : null}
                {this.state.document.type !== 2 &&
                this.state.document.comment &&
                allowToShow && !this.props.hideCommentButton ? (
                    this.renderAction(ACTION_COMMENT, () =>
                        openComment(
                            this.getLabel(true) +
                            " : " +
                            this.state.document.title,
                            this.state.document
                        )
                    )
                ) : null}
                {allowToEdit ? (
                    this.renderAction(ACTION_EDIT, () =>
                        this.editDocument(this.state.document)
                    )
                ) : null}
                {allowToDelete ? (
                    this.renderAction(ACTION_REMOVE, () =>
                        this.deleteDocument(this.state.document)
                    )
                ) : null}
            </div>
        );
    }
}

DocumentButtons.propTypes = {
    document: PropTypes.object,
    entity: PropTypes.object,
    label: PropTypes.string,
    allowedAction: PropTypes.func,
    onDelete: PropTypes.func,
}

const styles = {
    icon: {
        fontSize: 24,
    },
};
