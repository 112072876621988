class Config{
    constructor(){
        this.env = 'sandbox';
        this.backend = 'https://api.sandbox.mrm.bpce.scripters.fr';
        this.kms = 'https://kms.mrm.bpce.audience-pro.com/';
        this.api = this.backend + '/api';
        this.notificationRefeshInterval = 60000 // ms
        this.threadRefeshInterval = 20000 // ms
    }
}

export default new Config();