import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ParameterStore from '../../Store/ParameterStore';
import { ModalContent } from '../Modal/ModalContent';
import { ButtonBar } from '../Modal/ButtonBar';
import { ActionButton } from '../Modal/ActionButton';
import {
    DOCUMENT_ACTION_ADD,
    DOCUMENT_ACTION_LIST,
    DOCUMENT_ACTION_SHOW,
    DOCUMENT_CATEGORY_MODELING_DOCUMENTATION,
    DOCUMENT_CATEGORY_ONGOING_MONITORING_DOCUMENTATION,
    DocumentManager,
} from '../Display/DocumentManager/DocumentManager';
import { Checkbox, FormControlLabel, FormGroup, Paper } from '@material-ui/core';
import { EntityForm } from '../Forms/EntityForm/EntityForm';
import Http from '../../Services/Http';
import APIResourceStore from '../../Store/APIResourceStore';
import Modal from '../../Services/Modal';

export const UploadDocumentationModal = (props) => {
    const { resourceDetailComponent } = props;

    const [review, setReview] = useState(props.review);
    const [loading, setLoading] = useState(false);
    const [selection, setSelection] = useState([]);
    const [certifyChecked, setCertifyChecked] = useState(false);

    const reviewModelingDocumentationFields = {
        documentsEntities: {
            title: 'Modeling Documentation',
            type: 'documents',
            params: {
                entityResource: 'reviews',
                propertyName: 'documentsEntities',
                fieldName: 'Document',
                allowedCategory: true,
                hideCategory: true,
                links: false,
                allowedAction: (entity, document, action) => {
                    if (
                        ![DOCUMENT_CATEGORY_ONGOING_MONITORING_DOCUMENTATION, DOCUMENT_CATEGORY_MODELING_DOCUMENTATION]
                            .map(ParameterStore)
                            .includes(document?.category)
                    ) {
                        return false;
                    }

                    return [DOCUMENT_ACTION_ADD, DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_LIST].includes(action);
                },
                forceCategory: () => ParameterStore(DOCUMENT_CATEGORY_MODELING_DOCUMENTATION), // fonction pour retarder le moment où on récupère la valeur du paramètre, pour qu'il soit non "false"
                defaultValues: {
                    category: ParameterStore(DOCUMENT_CATEGORY_MODELING_DOCUMENTATION) ?? null,
                },
            },
        },
    };

    const certify = async () => {
        setLoading(true);
        await Http.post(`reviews/${review.id}/certifyDocuments`, {
            ids: selection,
        });
        const reviewUpdated = await APIResourceStore.resources.reviews.apiGetOne(review.id, true);
        resourceDetailComponent.entity = reviewUpdated;
        resourceDetailComponent.forceUpdate();
        Modal.close();
    };

    return (
        <ModalContent>
            <EntityForm entity={review} onUpdate={setReview} fields={reviewModelingDocumentationFields} />
            <Paper style={{ marginTop: '1em' }}>
                <h2 className="background-linear-gradient" style={{ color: 'white' }}>
                    Models documents
                </h2>
                <DocumentManager
                    entity={review}
                    values={review.certifiableModelDocumentsEntities}
                    allowedAction={(entity, document, action) =>
                        [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_LIST].includes(action)
                    }
                    allowedCategory={true}
                    defaultValues={{ categorySystemId: DOCUMENT_CATEGORY_MODELING_DOCUMENTATION }}
                    onSelectionChange={setSelection}
                />
                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                        label="I certify that these documents are up to date and can be used for the review"
                        control={
                            <Checkbox
                                checked={certifyChecked}
                                onChange={(event) => setCertifyChecked(event.target.checked)}
                            />
                        }
                    />
                </FormGroup>
            </Paper>

            <ButtonBar>
                <ActionButton loading={loading} disabled={loading || !certifyChecked || selection.length < 1} onClick={certify}>
                    Certify
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
UploadDocumentationModal.propTypes = {
    review: PropTypes.shape({
        id: PropTypes.number,
        certifiableModelDocumentsEntities: PropTypes.array,
    }),
    resourceDetailComponent: PropTypes.shape({
        entity: PropTypes.object,
        forceUpdate: PropTypes.func,
    }),
};
