import { APIResource, CONTEXT_DETAIL } from '../../../Services/APIResource/APIResource';
import React, { Component } from 'react';
import Icon from "@material-ui/core/Icon";
import Http from "../../../Services/Http";
import Alert from "../../../Services/Alert";
import {
    DOCUMENT_ACTION_ADD, DOCUMENT_ACTION_DELETE, DOCUMENT_ACTION_EDIT,
    DOCUMENT_ACTION_LIST,
    DOCUMENT_ACTION_SHOW,
    DOCUMENT_CATEGORY_MINUTES,
    DOCUMENT_CATEGORY_POST_COMMITTEE_REPORT, DOCUMENT_CATEGORY_PRESENTATION,
    DocumentButtons,
    DocumentManager
} from "../DocumentManager/DocumentManager";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
    CircularProgress,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    InputLabel,
    TableBody,
    TextareaAutosize,
    Tooltip, Typography
} from "@material-ui/core";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ParameterStore, {
    getParamByIri,
    userHasContributingRights,
    userHasRoleIG,
    userHasRoleMRM,
    userHasSpecificRole,
    userHasValidatorRights,
    userValidatorTeamManagedRights,
    userHasDeveloperRights,
    userIsVal,
    getParamBySystemId,
    userHasRoleADMIN,
    userHasRoleSTD,
} from "../../../Store/ParameterStore";
import User, {ROLE as Role} from "../../../Services/User/User";
import Modal from '../../../Services/Modal';
import { ModalContent } from '../../Modal/ModalContent';
import { LoadingSpinner } from '../../LoadingIndicator/LoadingSpinner';
import { ActionButton } from '../../Modal/ActionButton';
import { ButtonBar } from '../../Modal/ButtonBar';
import {
    documentAllowedActionAdd,
    documentAllowedActionEdit,
    documentAllowedActionDelete,
    documentAllowedActionShow,
    REVIEW_STATUS_NA,
    REVIEW_STATUS_NONE,
    REVIEW_STATUS_PLANNING,
    REVIEW_STATUS_PLANNING_AWAITING,
    REVIEW_STATUS_ASSESSMENT,
    REVIEW_STATUS_ASSESSMENT_AWAITING,
    REVIEW_STATUS_CLOSED
} from "../../../Admin/ReviewAdmin";
import FieldProviderStore from "../../../Services/APIResource/FieldProviders/__FieldProviderStore";
import Navigation from "../../../Services/Navigation";
import {OpenModal} from "../../Modal/OpenModal";
import APIResourceStore from "../../../Store/APIResourceStore";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ButtonColumn } from '../../Modal/ButtonColumn';
import {EntityForm} from "../../Forms/EntityForm/EntityForm";
import {
    PARAMETER_TYPE_REVIEW_COMMITTEE_SUBTYPE,
    PARAMETER_TYPE_REVIEW_COMMITTEE_TYPE
} from "../../../Admin/ParameterAdmin";
import ParameterProvider from "../../../Services/APIResource/FieldProviders/ParameterProvider";
import EntityProvider from "../../../Services/APIResource/FieldProviders/EntityProvider";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import { DeleteDocumentForm } from '../../Document/DeleteDocumentForm';
import ModalDocument from '../../../Services/ModalDocument';
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";

const TYPE_MEETING_CREATION   = 0;//Meeting Creation
const TYPE_COMMITTEE_CREATION = 5;//Committee Creation
const TYPE_MEETING_UPDATE     = 6;//Meeting Update
const TYPE_COMMITTEE_UPDATE   = 7;//Committee Update
const TYPE_DOCUMENT_UPLOAD    = 1;//Document upload
const TYPE_STATUS_CHANGE      = 2;//Status change
const TYPE_DOCUMENT_REMOVE    = 3;//Document removal
const TYPE_COMMENT            = 4;//Comment
const TYPE_DOCUMENT_CERTIFICATION = 8;

let typesMap = {
    TYPE_MEETING_CREATION: 'Meeting Creation',
    TYPE_COMMITTEE_CREATION: 'Committee Creation',
    TYPE_DOCUMENT_UPLOAD: 'Document upload',
    TYPE_STATUS_CHANGE: 'Status change',
    TYPE_DOCUMENT_REMOVE: 'Document removal',
    TYPE_COMMENT: 'Comment',
    TYPE_DOCUMENT_CERTIFICATION: 'Document certification',
};

typesMap = Object.fromEntries(Object.entries(typesMap).map(([k, v]) => {
    return [eval(k), v];
}));

const ReviewEventTypesMap = typesMap;

class ReviewEvent extends Component {

    constructor(props) {
        super(props);
        /** @type {{resource: import('../../../Services/APIResource/APIResource').APIResource, entity: {reviewEventsEntities: Array, documents: Array<string>}}} */
        this.props;

        this.state = {
            filteredEvents: [],
            filter: null,
            progress: false,
            openMenu: false,
            anchorEl: React.createRef(),
            entity: this.props.entity,
            isLoadingData: false,
        };
        this.eventsResource = new APIResource({
            id: 'review_events',
        });
        this.documentsResource = new APIResource({
            id: 'documents',
        });
        this.export = this.export.bind(this);
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        this.setState({filteredEvents: this.props.entity.reviewEventsEntities, entity: this.props.entity});
        this.filter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.filter !== prevState.filter) {
            this.filter();
        }
        if (this.props.entity !== prevProps.entity) {
            this.setState({filteredEvents: this.props.entity.reviewEventsEntities, entity: this.props.entity});
            this.filter();
        }
        if (this.state.entity !== prevState.entity) {
            this.setState({filteredEvents: this.state.entity.reviewEventsEntities});
            this.filter();
        }
    }

    setLoadingData = (state) => {
        this.setState({ isLoadingData: !!state });
    };

    handleClick = event => {
        this.setState({ openMenu: true, anchorEl: event.currentTarget });
    };

    handleClose = event => {
        this.setState({ openMenu: false, anchorEl: null });
    };

    getIcon(event){
        if(event.type === TYPE_MEETING_CREATION || event.type === TYPE_MEETING_UPDATE) {//Meeting Creation
            return 'fa-calendar-alt';
        }else if(event.type === TYPE_COMMITTEE_CREATION || event.type === TYPE_COMMITTEE_UPDATE){//Meeting Creation
            return 'fa-gavel';
        }else if(
            event.type === TYPE_DOCUMENT_UPLOAD//Document upload
            || event.type === TYPE_DOCUMENT_REMOVE//Document removal
            || event.type === TYPE_DOCUMENT_CERTIFICATION
        ){
            return 'fa-file-import';
        }else if(event.type === TYPE_STATUS_CHANGE){//Status change
            return 'fa-sync-alt';
        }else if(event.type === TYPE_COMMENT){//Comment
            return 'fa-comment-alt';
        }
    }

    filter() {
        let self = this;
        let filteredEvents = [];
        if (this.state.filter === null || this.state.filter === -1) {
             filteredEvents = self.state.entity.reviewEventsEntities;
        } else {
            filteredEvents = self.state.entity.reviewEventsEntities.filter((event) => {
                let filters = [this.state.filter];
                // On gère les types MEETING/COMMITTEE "CREATION" et "UPDATE" de la même façon
                if (this.state.filter === TYPE_COMMITTEE_CREATION) {
                    filters = [TYPE_COMMITTEE_CREATION, TYPE_COMMITTEE_UPDATE];
                }
                if (this.state.filter === TYPE_MEETING_CREATION) {
                    filters = [TYPE_MEETING_CREATION, TYPE_MEETING_UPDATE];
                }
                if (filters.includes(event.type)) {
                    return event;
                }
            });
        }

        //Check access right
        let events = [];
        if(filteredEvents === undefined){
            filteredEvents = [];
        }
        filteredEvents.forEach((event) => {
            if(
                userIsVal(User, self.state.entity)
                || userHasDeveloperRights(User, self.state.entity)
                || userHasRoleMRM()
                || userHasRoleIG()
            ){
                events.push(event);
                return;
            }

            if(
                (
                    event.type === TYPE_MEETING_CREATION
                    || event.type === TYPE_MEETING_UPDATE
                    || event.type === TYPE_COMMITTEE_CREATION
                    || event.type === TYPE_COMMITTEE_UPDATE
                    || event.type === TYPE_STATUS_CHANGE
                    || event.type === TYPE_COMMENT
                )
                && (
                    userHasContributingRights(User.getId(), self.state.entity)
                    || self.state.entity.amIMemberOfDeveloperTeam
                    || self.state.entity.amIModelOwner
                    || self.state.entity.amIModelOwnerDelegated
                )
            ){
                events.push(event);
            } else if (event.type === TYPE_COMMENT && event.user === `/api/users/${User.getId()}`) {
                events.push(event);
            }

            if(event.type === TYPE_DOCUMENT_UPLOAD || event.type === TYPE_DOCUMENT_CERTIFICATION) {
                //Un auteur voit toujours ses propres documents
                if(
                    event.document !== null
                    && event.document.author
                    && event.document.author === '/api/users/' + User.getId()
                ){
                    events.push(event);
                    return;
                }
                if (
                    documentAllowedActionShow(self.state.entity, event.document)
                ) {
                    events.push(event);
                }
            }


        })

        this.setState({filteredEvents: events});
    }

    export(){
        this.setState({progress: true});
        let exportEventIds = this.state.filteredEvents.map(event => event.id);
        Http.post('review_events/export', {ids: exportEventIds}).then((response) => {
            if(response.status === 'ERROR'){
                Alert.show({ message : response.message, type: 'error'});
            }else if(response.status === 'OK'){
                if(response.route && response.fileName){
                    Http.openFile(response.route, response.fileName);
                }
            }else{
                Alert.show({ message : "File can't be downloaded", type: "error"});
            }
            this.setState({progress: false});
        });
    }

    downloadAllDocuments() {
        /**
         * @see https://app.asana.com/0/1134038211401766/1204388062709633/f pour la règle de choix des documents à télécharger
         */
        Http.openEncryptedFiles(this.state.entity.reviewEventsEntities.reduce((acc, e) => {
            return [
            ...acc, 
            ...(e?.['document']?.['id'] ? [e?.['document']?.['id']] : []),
            ...(e?.['reviewCommittee']?.['committeeReviewDocument']?.['minutes'].map(d => d?.['id']) || []),
            ...(e?.['reviewCommittee']?.['committeeReviewDocument']?.['presentations'].map(d => d?.['id']) || []),
            ]}
        , []));
    }

    openCommentModal(){
        let self = this;
        Modal.open({
            title: "New comment",
            content: (
                <ReviewEventComment 
                    entity={self.state.entity}
                    resource={this.props.resource} 
                    onChange={(entity) => self.update(entity)}
                />)
        });
    }

    openReviewCommitteeModal(onlyForDocuments = false, reviewCommitteePath = null){
        let self = this;
        Modal.open({
            title: onlyForDocuments ? "Update documents" : "New Committee",
            content: (
                <ReviewCommitteeForm
                    entity={self.state.entity}
                    resource={this.props.resource}
                    preUpdate={() => this.setLoadingData(true)}
                    onUpdate={(entity) => {
                        self.update(entity);
                    }}
                    onlyForDocuments={onlyForDocuments}
                    reviewCommitteePath={reviewCommitteePath}
                />),
            modalStyle: { width: "900px" },
        });
    }

    openReviewMeetingModal(){
        let self = this;
        Modal.open({
            title: "New Meeting",
            content: (
                <ReviewMeetingForm
                    entity={self.state.entity}
                    resource={this.props.resource}
                    preUpdate={() => this.setLoadingData(true)}
                    onUpdate={(entity) => {
                        self.update(entity);
                    }}
                />),
            modalStyle: { width: "900px" },
        });
    }

    openDissociateReviewCommitteeModal(event){
        let self = this;
        Modal.open({
            title: "Dissociate Committee",
            content: (
                <ReviewCommitteeDissociateForm
                    entity={self.state.entity}
                    event={event}
                    resource={this.props.resource}
                    onUpdate={(entity) => {
                        self.update(entity);
                    }}
                />),
            size: "small",
        });
    }

    deleteComment(event){
        let self = this;
        self.setLoadingData(true);
        this.eventsResource.apiDelete(event).then(() => {
            Alert.show({message: 'Comment successfully deleted.', type: "success"});
            this.props.resource.getItem(self.state.entity.id, true).then(entity => this.update(entity));
        });
    }

    deleteEvent(reviewEvent){
        let self = this;
        self.setLoadingData(true);
        this.eventsResource.apiDelete(reviewEvent).then(() => {
            Alert.show({message: 'Event successfully deleted.'});
            this.props.resource.getItem(self.state.entity.id, true).then(entity => this.update(entity));
        });
    }

    update(entity){
        this.setState({entity, isLoadingData: false});
        if(this.props.resourceDetailComponent){
            this.props.resourceDetailComponent.entity = entity;
            this.props.resourceDetailComponent.forceUpdate();
        }
    }

    deleteDocument(document){
        let self = this;
        self.setLoadingData(true);
        
        ModalDocument.open({
            title: "Delete this document",
            content: <DeleteDocumentForm
                    document={document}
                    resource={this.documentsResource}
                    callback={() => {
                        Alert.show({
                            message: 'Document successfully deleted.',
                            type: "success",
                        });
                        self.props.resource.getItem(self.state.entity.id, true).then(entity => self.update(entity));
                        ModalDocument.close();
                    }}
                />,
            size: "small",
        })
    }

    uploadDocumentForCommittee(committeeId){
        this.openReviewCommitteeModal(true, `/api/review_committees/${committeeId}`);
    }

    renderItem(index){
        const event = this.state.filteredEvents[index];
        return <TableRow key={`event-${index}`} className={ (index%2 ? 'odd' : 'even') + ' type-'+event.type}>
            <TableCell>
                <Grid container>
                    <Icon className={"fa " + this.getIcon(event)}/>
                    <span className={'cell-content'}>
                        <div className="left" style={{whiteSpace: 'normal'}}>
                            <span className={'date'}>{event.dateString}</span><br/>
                            <span className={'title'}>{event.title}</span><br/>
                            <span className={'description'}>{event.description}</span>
                        </div>
                        <div className="right">
                            <div className={"left"}>
                                 {
                                     event.reviewCommittee && event.reviewCommittee.committeeReviewDocument ? (
                                         <>
                                             {this.renderEventItemCommitteeDocumentPart(event.reviewCommittee)}
                                         </>
                                     ) : null
                                 }
                            </div>
                            <div className={"right mw"}>
                                {
                                    event.document && event.type === TYPE_DOCUMENT_UPLOAD ?
                                        <DocumentButtons
                                            key={'document_' + index}
                                            entity={this.state.entity}
                                            document={event.document}
                                            hideCommentButton={true}
                                            allowedAction={(review, document, action) => {
                                                if(action === DOCUMENT_ACTION_SHOW){
                                                    return documentAllowedActionShow(review, document);
                                                }else if(action === DOCUMENT_ACTION_ADD){
                                                    return documentAllowedActionAdd(review, document);
                                                }else if(action === DOCUMENT_ACTION_EDIT){
                                                    return documentAllowedActionEdit(review, document);
                                                }else if(action === DOCUMENT_ACTION_DELETE){
                                                    return (documentAllowedActionDelete(review) && (userHasRoleMRM() || (userHasValidatorRights(User.getId(), this.state.entity) && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED))))
                                                    || (userValidatorTeamManagedRights(User.profile.managedScopes, this.state.entity)  && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED));
                                                }
                                                return false;
                                            }}
                                            callback={() => {
                                                this.setLoadingData(true);
                                                this.props.resource.apiGetOne(this.state.entity.id, true).then((entity) => {
                                                    this.update(entity);
                                                });
                                            }}
                                        />
                                        : null
                                }
                                {
                                    event.document && event.type === TYPE_DOCUMENT_CERTIFICATION ?
                                        <DocumentButtons
                                            key={'document_' + index}
                                            entity={this.state.entity}
                                            document={event.document}
                                            hideCommentButton={true}
                                            allowedAction={(review, document, action) => {
                                                if(action === DOCUMENT_ACTION_SHOW){
                                                    return documentAllowedActionShow(review, document);
                                                }
                                                return false;
                                            }}
                                            callback={() => {
                                                this.setLoadingData(true);
                                                this.props.resource.apiGetOne(this.state.entity.id, true).then((entity) => {
                                                    this.update(entity);
                                                });
                                            }}
                                        />
                                        : null
                                }
                                {
                                    event.reviewCommittee && [TYPE_MEETING_CREATION, TYPE_COMMITTEE_CREATION, TYPE_MEETING_UPDATE, TYPE_COMMITTEE_UPDATE].includes(event.type)
                                    ? (<>
                                        <div className={"thread-actions"}>
                                            { userHasRoleMRM() || [TYPE_MEETING_CREATION, TYPE_MEETING_UPDATE].includes(event.type)?
                                            <OpenModal
                                                instanceId="review_committees"
                                                id={event.reviewCommittee['id']}
                                                context={CONTEXT_DETAIL}
                                                modalTitle='Detail'
                                                customFirstRessourceName={[TYPE_MEETING_CREATION, TYPE_MEETING_UPDATE].includes(event.type) ? 'Meeting' : 'Committee'}
                                                allowStayInModal={true}
                                                //Pour le context d'édition ou en cas de d'édition depuis la modal detail
                                                parentInstanceId={this.props.resource.instanceId}
                                                parentId={this.state.entity.id}
                                                postSaveRedirectUrl={`/resource/${this.props.resource.instanceId}/${this.state.entity.id}/detail?tab=Thread`}
                                                parentResourceComponent={this.props.resourceDetailComponent}
                                            />
                                            : null }
                                        </div>
                                        <div className={"thread-actions"}>
                                            { (userHasRoleMRM() || (userHasValidatorRights(User.getId(), this.state.entity) && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED)))
                                                ?
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="button-meeting"
                                                    style={styles.actionLink}
                                                    onClick={() => this.openDissociateReviewCommitteeModal(event)}
                                                >
                                                    Dissociate
                                                </Button>
                                                : null }
                                        </div>
                                    </>)
                                    : null
                                }
                                {
                                    event
                                    && event.type === TYPE_COMMENT
                                    && (
                                        event.author === '/api/users/' + User.getId()
                                        || userHasValidatorRights(User.getId(), this.state.entity)
                                        || userHasRoleMRM()
                                    ) ?
                                        <div className={"thread-actions"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="button-meeting"
                                                style={styles.actionLink}
                                                onClick={() => this.deleteComment(event)}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                        : null
                                }
                                {
                                    userHasRoleADMIN() && <div className={'thread-actions'}>
                                        <Tooltip title={"Delete this event from the thread. Beware ! The committee/document will not be detached from the review, please use the \"dissociate\" button for this action."}>
                                            <IconButton
                                                aria-label="delete"
                                                color={'secondary'}
                                                style={{ marginTop: '4px' }}
                                                onClick={() => this.deleteEvent(event)}
                                            >
                                                <i className="fas fa-times-circle" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                }
                            </div>
                        </div>
                    </span>
                </Grid>
            </TableCell>
        </TableRow>;
    }

    renderEventItemCommitteeDocumentPart(reviewCommittee){
        let self = this;
        let committeeReviewDocument = reviewCommittee.committeeReviewDocument;
        let documents = [];

        if(Array.isArray(committeeReviewDocument.minutes) && committeeReviewDocument.minutes.length > 0) {
            committeeReviewDocument.minutes.forEach((document, index) => {
                documents.push(
                    <TableRow key={`committee-row-${index}`}>
                        <TableCell width={100} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                            {getParamBySystemId(DOCUMENT_CATEGORY_MINUTES).label}
                        </TableCell>
                        <TableCell width={40} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                            <IconButton style={{padding: 0}} className={'flat-button'} disabled={true}>
                                <CheckIcon className="text-success" />
                            </IconButton>
                        </TableCell>
                        <TableCell width={50} style={{padding: 0, border: 'none'}}>
                            <DocumentButtons
                                key={'document_' + index}
                                entity={this.state.entity}
                                document={document}
                                hideCommentButton={true}
                                showTooltip={true}
                                small={true}
                                flat={true}
                                allowedAction={(review, document, action) => {
                                    if(action === DOCUMENT_ACTION_SHOW){
                                        return documentAllowedActionShow(review, document);
                                    }
                                    return false;
                                }}
                                onDeleteCallback={() => {
                                    this.setLoadingData(true);
                                    this.props.resource.apiGetOne(this.state.entity.id, true).then((entity) => {
                                        this.update(entity);
                                    });
                                }}
                            />
                        </TableCell>
                        <TableCell width={40} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                            {
                                documentAllowedActionDelete(this.state.entity, document) &&
                                (userHasRoleMRM() || ((userHasValidatorRights(User.getId(), this.state.entity) || userValidatorTeamManagedRights(User.profile.managedScopes, this.state.entity)) && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED))) &&
                                <IconButton style={{padding: 0}} className={'flat-button'} onClick={() => self.deleteDocument(document)}>
                                    <DeleteIcon className="text-danger" />
                                </IconButton>
                            }
                        </TableCell>
                    </TableRow>
                );
            })
        }else{
            documents.push(
                <TableRow>
                    <TableCell width={100} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                        {getParamBySystemId(DOCUMENT_CATEGORY_MINUTES).label}
                    </TableCell>
                    <TableCell width={40} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                        <IconButton style={{padding: 0}} className={'flat-button'} disabled={true}>
                            <CloseIcon className="text-danger" />
                        </IconButton>
                    </TableCell>
                    <TableCell width={50} style={{padding: 0, border: 'none'}}>
                        { (userHasRoleMRM() || ((userHasValidatorRights(User.getId(), this.state.entity) || userValidatorTeamManagedRights(User.profile.managedScopes, this.state.entity)) && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED))) &&
                        <IconButton style={{padding: 0}} className={'flat-button'} onClick={() => {
                            this.uploadDocumentForCommittee(reviewCommittee.id)
                        }}>
                            <CloudUploadIcon />
                        </IconButton>
                        }
                    </TableCell>
                    <TableCell width={40} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                    </TableCell>
                </TableRow>
            );
        }

        if(reviewCommittee.isCommittee) {
            if (Array.isArray(committeeReviewDocument.presentations) && committeeReviewDocument.presentations.length > 0) {
                committeeReviewDocument.presentations.forEach((document, index) => {
                    documents.push(
                        <TableRow>
                            <TableCell width={100} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                                {getParamBySystemId(DOCUMENT_CATEGORY_PRESENTATION).label}
                            </TableCell>
                            <TableCell width={40} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                                <IconButton style={{padding: 0}} className={'flat-button'} disabled={true}>
                                    <CheckIcon className="text-success"/>
                                </IconButton>
                            </TableCell>
                            <TableCell width={50} style={{padding: 0, border: 'none'}}>
                                <DocumentButtons
                                    key={'document_' + index}
                                    entity={this.state.entity}
                                    document={document}
                                    hideCommentButton={true}
                                    showTooltip={true}
                                    small={true}
                                    flat={true}
                                    allowedAction={(review, document, action) => {
                                        if (action === DOCUMENT_ACTION_SHOW) {
                                            return documentAllowedActionShow(review, document);
                                        }
                                        return false;
                                    }}
                                    onDeleteCallback={() => {
                                        this.setLoadingData(true);
                                        this.props.resource.apiGetOne(this.state.entity.id, true).then((entity) => {
                                            this.update(entity);
                                        });
                                    }}
                                />
                            </TableCell>
                            <TableCell width={40} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                                {

                                    documentAllowedActionDelete(this.state.entity, document) &&
                                    (userHasRoleMRM() || ((userHasValidatorRights(User.getId(), this.state.entity) || userValidatorTeamManagedRights(User.profile.managedScopes, this.state.entity)) && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED))) &&
                                    <IconButton style={{padding: 0}} className={'flat-button'} onClick={() => self.deleteDocument(document)}>
                                        <DeleteIcon className="text-danger" />
                                    </IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    );
                })
            } else {
                documents.push(
                    <TableRow>
                        <TableCell width={100} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                            {getParamBySystemId(DOCUMENT_CATEGORY_PRESENTATION).label}
                        </TableCell>
                        <TableCell width={40} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                            <IconButton style={{padding: 0}} className={'flat-button'} disabled={true}>
                                <CloseIcon className="text-danger"/>
                            </IconButton>
                        </TableCell>
                        <TableCell width={50} style={{padding: 0, border: 'none'}}>
                            { (userHasRoleMRM() || ((userHasValidatorRights(User.getId(), this.state.entity) || userValidatorTeamManagedRights(User.profile.managedScopes, this.state.entity)) && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED))) &&
                            <IconButton style={{padding: 0}} className={'flat-button'} onClick={() => {
                                this.uploadDocumentForCommittee(reviewCommittee.id)
                            }}>
                                <CloudUploadIcon/>
                            </IconButton>
                            }
                        </TableCell>
                        <TableCell width={40} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                        </TableCell>
                    </TableRow>
                );
            }
        }

        return <table
            className={'documents-for-committee'}
        >
            <TableBody>
                {documents}
            </TableBody>
        </table>;
    }

    renderEvents(events){
        let items = [];
        events.forEach((event, index) => {
            items.push(this.renderItem(index))
        })

        return items;
    }

    renderLoadingHeader(isLoadingData) {

        return isLoadingData ? (
            <TableRow key={'loading'} className="loading" style={{margin:0}}>
                <TableCell style={{padding:0}}>
                    <LinearProgress />
                </TableCell>
            </TableRow>
        ) : (
            <TableRow key={'loading'} style={{ height: '4px' }}></TableRow>
        );
    }

    render() {
        const id = this.state.openMenu ? 'menu-list-grow' : undefined;
        const { anchorEl, openMenu } = this.state;
        let predictedDocumentCategory = null;
        if(
            this.state.entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)
            || this.state.entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)
        ){
            predictedDocumentCategory = DOCUMENT_CATEGORY_POST_COMMITTEE_REPORT;
        }

        return (
            <div className={'review-event'}>
                <div className={"actions"}>
                    <Grid
                        container
                        spacing={2}
                        className={'container detail-inner-new-button'}
                    >
                        <div className={'menu'}>
                            <div className={'buttons'}>
                                <Button
                                    className={"button-list"}
                                    aria-describedby={id}
                                    open={openMenu}
                                    aria-haspopup="true"
                                    onClick={this.handleClick}
                                >
                                    <Icon className={`fa fa-filter`}/>
                                    <span className={'separator'}>|</span>
                                    <span className={'menu-text'}>{this.state.filter !== null ? (this.state.filter >= 0 ? ReviewEventTypesMap[this.state.filter] : 'All') : 'Choose a type'}</span>
                                    <Icon className={`fa fa-angle-down`}/>
                                </Button>
                                <Popper
                                    open={openMenu}
                                    id={id}
                                    anchorEl={anchorEl}
                                    role={undefined} transition disablePortal
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={this.handleClose}>
                                                    <MenuList id="menu-list-grow">
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: -1})}
                                                            value={-1}
                                                        >
                                                            All
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_MEETING_CREATION})}
                                                            value={TYPE_MEETING_CREATION}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_MEETING_CREATION] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_COMMITTEE_CREATION})}
                                                            value={TYPE_COMMITTEE_CREATION}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_COMMITTEE_CREATION] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_DOCUMENT_UPLOAD})}
                                                            value={TYPE_DOCUMENT_UPLOAD}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_DOCUMENT_UPLOAD] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_DOCUMENT_CERTIFICATION})}
                                                            value={TYPE_DOCUMENT_CERTIFICATION}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_DOCUMENT_CERTIFICATION] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_DOCUMENT_REMOVE})}
                                                            value={TYPE_DOCUMENT_REMOVE}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_DOCUMENT_REMOVE] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_STATUS_CHANGE})}
                                                            value={TYPE_STATUS_CHANGE}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_STATUS_CHANGE] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_COMMENT})}
                                                            value={TYPE_COMMENT}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_COMMENT] }
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                                { this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) && !userHasSpecificRole(Role.IG) ?
                                    <>
                                        <DocumentManager
                                            values={this.state.entity.documentEntities}
                                            entity={this.state.entity}
                                            entityResource={this.props.resource.instanceId}
                                            allowedCategory={true}
                                            onlyButton={true}
                                            preUpdate={() => this.setLoadingData(true)}
                                            onUpdate={(entity) => this.update(entity)}
                                            addButton={{
                                                icon: "fa-file-import",
                                                color: "#ffffff"
                                            }}
                                            defaultValues={{
                                                category: predictedDocumentCategory ? ParameterStore(predictedDocumentCategory) : null
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="button-meeting"
                                            style={styles.actionLink}
                                            onClick={this.openReviewMeetingModal.bind(this)}
                                        >
                                            <Icon className={'fa fa-calendar-alt'} style={{color: '#ffffff'}}/>
                                            New meeting
                                        </Button>
                                        {
                                            !(this.state.entity.request && !this.state.entity.reviewStatus)
                                            && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_NA)
                                            && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_NONE)
                                            && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_PLANNING)
                                            && this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_PLANNING_AWAITING)
                                            &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="button-meeting"
                                            style={styles.actionLink}
                                            onClick={() => this.openReviewCommitteeModal()}
                                        >
                                            <Icon className={'fa fa-gavel'} style={{color: '#ffffff'}}/>
                                            New committee
                                        </Button> }
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="button-general comment"
                                            onClick={this.openCommentModal.bind(this)}
                                        >
                                            <Icon className={'fa fa-comment-alt'} style={{color: '#ffffff'}}/>
                                            New comment
                                        </Button>
                                    </>
                                :
                                userHasRoleMRM() ?
                                    <div
                                        className={'new-button'}
                                    >
                                        <DocumentManager
                                            values={this.state.entity.documentEntities}
                                            entity={this.state.entity}
                                            entityResource={this.props.resource.instanceId}
                                            allowedCategory={!(this.state.entity.amIModelOwner || this.state.entity.amIModelOwnerDelegated)}
                                            onlyButton={true}
                                            preUpdate={() => this.setLoadingData(true)}
                                            onUpdate={(entity) => this.update(entity)}
                                            addButton={{
                                                icon: "fa-file-import",
                                                color: "#ffffff"
                                            }}
                                            defaultValues={{
                                                category: predictedDocumentCategory ? ParameterStore(predictedDocumentCategory) : null
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="button-meeting"
                                            style={styles.actionLink}
                                            onClick={() => this.openReviewCommitteeModal()}
                                        >
                                            <Icon className={'fa fa-gavel'} style={{color: '#ffffff'}}/>
                                            New committee
                                        </Button>
                                    </div>
                                : null }
                                
                                <Button
                                    onClick={() => this.export()}
                                    aria-label="Export"
                                    disabled={this.state.progress}
                                    size={'small'}
                                    variant="contained"
                                    className={'export'}
                                >
                                    { this.state.progress ?
                                        <Icon className={`fa fa-spinner fa-spin`}/>
                                        :
                                        <Icon className={"fa fa-upload"}/>
                                    }
                                    Export
                                </Button>
                                {userHasRoleSTD() ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        aria-label="Download all documents"
                                        className="export"
                                        style={styles.actionLink}
                                        onClick={this.downloadAllDocuments.bind(this)}
                                    >
                                        <Icon className={'fa fa-upload'} style={{ color: '#ffffff' }} />
                                        Download all documents
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    </Grid>
                </div>
                <Grid item xs={12}>
                    <Paper className="container-no-padding table-scroll" style={this.state.isLoadingData ? {marginTop:0}:{}}>
                        <Table
                            className={'table-display small'}
                            size={'small'}
                        >
                            <TableBody>
                                {this.renderLoadingHeader(this.state.isLoadingData)}
                                {this.renderEvents(this.state.filteredEvents)}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </div>
        );
    }
};
class ReviewEventComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            disabled: false,
        };
    }

    comment() {
        let self = this;
        let commentEvent = {
            title: this.state.title,
            description: this.state.description,
            type: TYPE_COMMENT,
            reviewId: this.props.entity.id,
        };
        this.setState({ disabled: true });
        Http.post('review_events/comment', commentEvent).then((data) => {
            this.props.resource.apiGetOne(self.props.entity.id, true).then((entity) => {
                this.props.onChange(entity);
                this.setState({ disabled: false });
                Modal.close();
            });
        });
    }

    render() {
        return (
            <ModalContent className="event-modal-comment">
                <FormControl>
                    <InputLabel htmlFor="my-input">Title*</InputLabel>
                    <Input 
                        id="comment-title"
                        aria-describedby="comment-title" 
                        value={this.state.title ? this.state.title : ''}
                        onChange={(event) => this.setState({ title: event.target.value })} />
                </FormControl>
                <FormControl className="container_textarea">
                    <FormLabel>Description</FormLabel>
                    <TextareaAutosize
                        value={this.state.description ? this.state.description : ''}
                        onChange={(event) => this.setState({ description: event.target.value })}
                        rows={5}
                        rowsMax={10}
                    />
                </FormControl>
                <ButtonBar>
                    <ActionButton
                        disabled={this.state.disabled}
                        onClick={this.comment.bind(this)}
                    >
                        Send
                        {this.state.disabled && <LoadingSpinner />}
                    </ActionButton>
                </ButtonBar>
            </ModalContent>
        );
    }
}  

export default observer(ReviewEvent);

const styles = {
    actionLink: {
        marginLeft: 5,
        zIndex: 0,
    },
    loadingIndicator: {
        display: 'flex',
        transition: 'color 150ms',
        alignSelf: 'center',
        fontSize: '0.5rem',
        margin: '5rem 0.5rem 5rem 0.5rem',
        textAlign: 'center',
    },
    documentFormTitle: {
        fontSize: '30px',
        marginTop: '0',
        color: '#0dbbb7'
    }
};

export function committeeReviewDocumentFields(category){
    return {
        documentsEntities: {
            title: "Please attach documents",
            type: "documents",
            params: {
                entityResource: "committee_review_documents",
                propertyName: "documentsEntities",
                fieldName: "Document",
                allowedCategory: true,
                hideCategory: true,
                links: false,
                allowedAction: (entity, document, action) => {
                    if(action === DOCUMENT_ACTION_LIST){
                        return document && document.category === ParameterStore(category);
                    }
                    return [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action) && document.category === ParameterStore(category);
                },
                defaultValues : {
                    category: ParameterStore(category) ?? null
                },
            },
        },
    };
}

class ReviewCommitteeForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            reviewCommittee: this.props.reviewCommitteePath,
            reviewCommitteeIsCommittee: !this.props.onlyForDocuments,
            committeeReviewDocument: {
                review: `/api/reviews/${this.props.entity.id}`,
                documents: [],
                context: 'CONTEXT_ADD_DOCUMENT_TO_LINK_REVIEW_TO_COMMITTEE'
            },
            processing: false,
            loading: true
        }
    }

    componentDidMount() {
        let self = this;
        if(this.state.reviewCommittee !== null){
            APIResourceStore.resources.review_committees.getItemFromResourcePath(this.state.reviewCommittee, true).then((reviewCommittee) => {
                if(Array.isArray(reviewCommittee.committeeReviewDocumentsEntities)){
                    let crdForReview = reviewCommittee.committeeReviewDocumentsEntities.filter(crd => crd.review === `/api/reviews/${self.props.entity.id}`);
                    if(Array.isArray(crdForReview) && crdForReview.length > 0){
                        crdForReview = crdForReview[0];
                        crdForReview['@type'] = 'CommitteeReviewDocument';
                        crdForReview.context = 'CONTEXT_ADD_DOCUMENT_TO_LINK_REVIEW_TO_COMMITTEE';
                        self.setState({reviewCommitteeIsCommittee: reviewCommittee.isCommittee, committeeReviewDocument: crdForReview, loading: false});
                    }else{
                        self.setState({reviewCommitteeIsCommittee: reviewCommittee.isCommittee, loading: false});
                    }
                }
            })
        }else{
            self.setState({loading: false});
        }
    }

    save(){
        let self = this;
        if(self.state.reviewCommittee === null){
            Alert.show({message: 'Please select a committee.', type: 'error'});
            return;
        }
        self.setState({processing: true});
        if(self.props.preUpdate){
            self.props.preUpdate();
        }
        let committeeReviewDocument = this.state.committeeReviewDocument;
        APIResourceStore.resources.review_committees.getItemFromResourcePath(self.state.reviewCommittee, true).then((reviewCommittee) => {
            if(Array.isArray(reviewCommittee.reviews)){
                reviewCommittee.reviews.push(`/api/reviews/${self.props.entity.id}`);
            }else{
                reviewCommittee.reviews = [`/api/reviews/${self.props.entity.id}`];
            }
            committeeReviewDocument.committee = reviewCommittee['@id'];
            APIResourceStore.resources.review_committees.apiPut({
                '@id': reviewCommittee['@id'],
                id: reviewCommittee.id,
                reviews: reviewCommittee.reviews,
            }).then(() => {
                Alert.show({message: 'Committee successfully associated.', type: "success"});
                if(!committeeReviewDocument.id){
                    APIResourceStore.resources.committee_review_documents.apiPost(committeeReviewDocument).then((entity) => {
                        self.props.resource.getItem(self.props.entity.id, true).then((entity) => {
                            self.props.onUpdate(entity);
                        });
                        self.setState({processing: false});
                        Modal.close();
                    });
                }else{
                    APIResourceStore.resources.committee_review_documents.apiPut(committeeReviewDocument).then((entity) => {
                        self.props.resource.getItem(self.props.entity.id, true).then((entity) => {
                            self.props.onUpdate(entity);
                        });
                        self.setState({processing: false});
                        Modal.close();
                    });
                }

            });
        })
    }

    updateState = (callback) => {
        this.setState((p) => {
            const newEntity = callback(p.committeeReviewDocument);
            return {committeeReviewDocument: newEntity}
        })
    }

    genForm(){
        let fields = [];
        let self = this;

        if(this.props.onlyForDocuments === false){
            let reviewCommittee = {
                title: 'Choose a committee',
                type: 'entity',
                params: {
                    multi: false,
                    resource: 'review_committees',
                    displayField: 'displayString',
                    neededFields: ['type'],
                    filters: (committee, entity) => {
                        return committee.type === ParameterStore('REVIEW_COMMITTEE_TYPE_COMMITTEE') && (!entity.reviewCommittees || !entity.reviewCommittees.includes(committee['@id']));
                    }
                },
                issueButton: false,
                required: false,
                disabled: true
            };


            fields.push(<div className="field" key={'field-reviewCommittee'}>
                <div className={'entity-form'}>
                    {
                        FieldProviderStore[reviewCommittee.type].getEdit(
                            reviewCommittee,
                            this.state.reviewCommittee,
                            (value) => {
                                this.setState({reviewCommittee: value})
                            },
                            self.props.entity,
                            {}
                        )
                    }
                </div>
            </div>);
        }

        return(
            <div className="bulk-associate-validation-family">
                <div className="fields main-fields resource-edit">
                    {fields}
                    <div>
                        <h2 style={styles.documentFormTitle}>Minutes</h2>
                        <EntityForm
                            entity={this.state.committeeReviewDocument}
                            onUpdate={this.updateState}
                            fields={committeeReviewDocumentFields(DOCUMENT_CATEGORY_MINUTES)}
                        />
                    </div>
                    { this.state.reviewCommitteeIsCommittee &&
                    <div>
                        <h2 style={styles.documentFormTitle}>Presentation</h2>
                        <EntityForm
                            entity={this.state.committeeReviewDocument}
                            onUpdate={this.updateState}
                            fields={committeeReviewDocumentFields(DOCUMENT_CATEGORY_PRESENTATION)}
                        />
                    </div> }
                </div>
                <ButtonColumn>
                    <ActionButton
                        onClick={this.save.bind(this)}
                        loading={this.state.processing}
                        style={{marginBottom: "10px", width: "100%"}}
                    >
                        {this.props.onlyForDocuments === true ? 'Save' : 'Choose this existing committee'}
                    </ActionButton>
                {
                    this.props.onlyForDocuments === false &&
                    userHasRoleMRM() &&
                    <ActionButton
                        color="primary"
                        onClick={() => {
                            Modal.close();
                            Navigation.router.history.push(`/resource/review_committees/add/${this.props.entity.id}/${getParamByIri(ParameterStore('REVIEW_COMMITTEE_TYPE_COMMITTEE')).id}`);
                        }}
                        style={{marginBottom: "10px", width: "100%"}}
                        loading={this.state.processing}
                    >
                        Create a new committee
                    </ActionButton>
                }
                </ButtonColumn>
            </div>
        );
    }

    render(){
        return (
            <ModalContent>
                { this.state.loading ?
                    <LoadingIndicator styles={styles.loadingIndicator} />
                    :
                    this.genForm()
                }
            </ModalContent>
        );
    }
}

const meetingFields = {
    type: {
        title: "Type",
        type: "parameter",
        params: {
            type: PARAMETER_TYPE_REVIEW_COMMITTEE_TYPE,
            multi: false,
        },
        edit: (field, value, onChange, entity, routeParams) => {
            return ParameterProvider.getDisplay(field, entity.type, entity, {});
        },
    },
    subType: {
        title: "Sub-type",
        type: "parameter",
        params: {
            type: PARAMETER_TYPE_REVIEW_COMMITTEE_SUBTYPE,
            multi: false,
            filters: (parameter, entity, key, context) => {
                if (
                    !entity //Pas d'entity alors on est en liste, pour le cas de la liste Committee (la meeting n'existe pas), on ne souhaite pas avoir les sub-type des meetings en tant qu'option du select filter
                    && (
                        parameter['@id'] === ParameterStore('REVIEW_COMMITTEE_SUB_TYPE_KICK_OFF_MEETING') ||  //Kick-Off Meeting
                        parameter['@id'] === ParameterStore('REVIEW_COMMITTEE_SUB_TYPE_FOLLOW_UP_MEETING') || //Follow-up Meeting
                        parameter['@id'] === ParameterStore('REVIEW_COMMITTEE_SUB_TYPE_MID_TERM_MEETING') ||  //Mid-term Meeting
                        parameter['@id'] === ParameterStore('REVIEW_COMMITTEE_SUB_TYPE_CLOSING_MEETING')      //Closing Meeting

                    )
                ) {
                    return false;
                }
                return true;
            },
        },
    },
    date: {
        title: "Date",
        type: "date",
        required: true,
    },
    reviews: {
        title: "Reviews",
        type: "entity",
        params: {
            resource: "reviews",
            displayField: "toString",
            multi: true,
            tooltip: (entity) => entity.title,
        },
        edit: (field, value, onChange, entity, routeParams) => {
            return EntityProvider.getDisplay(field, value, entity)
        },
    },
    description: {
        title: "Description",
        type: "textarea",
    },
    documentsEntities: {
        title: "Please attach documents",
        type: "documents",
        params: {
            entityResource: "review_committees",
            propertyName: "documentsEntities",
            fieldName: "Document",
            allowedCategory: true,
            links: false,
            allowedAction: (entity, document, action) =>
                [DOCUMENT_ACTION_ADD, DOCUMENT_ACTION_SHOW,
            DOCUMENT_ACTION_LIST,
            DOCUMENT_ACTION_DELETE].includes(action),
            showAddButton: false,
            defaultValues: {
                categorySystemId: DOCUMENT_CATEGORY_MINUTES
            }
        },
    },
}


class ReviewMeetingForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            reviewCommittee: {
                type: ParameterStore('REVIEW_COMMITTEE_TYPE_MEETING'),
                subType: ParameterStore('REVIEW_COMMITTEE_SUB_TYPE_KICK_OFF_MEETING'),
                reviews: [this.props.entity['@id']],
                context: 'CONTEXT_ADD_DOCUMENT_TO_LINK_REVIEW_TO_COMMITTEE'
            },
            processing: false,
        }
    }

    updateState = (callback) => {
        this.setState((p) => {
            const newEntity = callback(p.reviewCommittee);
            return {reviewCommittee: newEntity}
        })
    }

    save(){
        let self = this;
        self.setState({processing: true});
        let hasError = false;
        Object.entries(meetingFields).forEach((field, index) => {
            if(field[1].required && !this.state.reviewCommittee[field[0]]){
                Alert.show({message: 'Please fill all required fields', type: "error"});
                self.setState({processing: false});
                hasError = true;
            }
        })

        if(!hasError){
            if(self.props.preUpdate){
                self.props.preUpdate();
            }
            let documents = this.state.reviewCommittee.documents;
            delete this.state.reviewCommittee.documents;
            APIResourceStore.resources.review_committees.apiPost(this.state.reviewCommittee).then((reviewCommittee) => {
                Alert.show({message: 'Meeting successfully created.', type: "success"});
                let committeeReviewDocument = {
                    documents,
                    review: this.props.entity['@id'],
                    committee: reviewCommittee['@id'],
                };
                APIResourceStore.resources.committee_review_documents.apiPost(committeeReviewDocument).then((entity) => {
                    self.props.resource.getItem(self.props.entity.id, true).then((entity) => {
                        self.props.onUpdate(entity);
                    });
                    self.setState({processing: false});
                    Modal.close();
                });
            });
        }
    }

    genForm(){
        return(
        <ModalContent className={'non-model-reject-form'}>
            <Grid container justify="center" spacing={2} className="container resource-edit">
                <Grid item xs={12}>
                    <EntityForm
                        entity={this.state.reviewCommittee}
                        onUpdate={this.updateState}
                        fields={meetingFields}
                        resourceId={"review_committees"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ButtonBar>
                        <ActionButton onClick={Modal.close} disabled={this.state.processing}>
                            Cancel
                        </ActionButton>
                        <ActionButton onClick={() => this.save()} loading={this.state.processing}>
                            Save
                        </ActionButton>
                    </ButtonBar>
                </Grid>
            </Grid>
        </ModalContent>
        );
    }

    render(){
        return(this.genForm());
    }
}

class ReviewCommitteeDissociateForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            processing: false,
        }
    }

    dissociate(){
        let self = this;
        let entity = self.props.entity;
        self.setState({processing: true});
        Http.post('review/dissociate/committee', {
            review: entity.id,
            committee: self.props.event.reviewCommittee.id
        }).then(() => {
            Alert.show({message: 'Committee successfully dissociated.', type: "success"});
            self.props.resource.getItem(self.props.entity.id, true).then(entity => self.props.onUpdate(entity));
            self.setState({processing: false});
            Modal.close();
        });
    }

    render(){
        return <div className="dissociate-committee">
            <div className="fields main-fields">
                <p>Are you sure you want to dissociate this meeting ?</p>
                <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Button variant="contained" color="secondary" className="save button-general" onClick={Modal.close}>
                        Cancel
                    </Button>
                    {
                        this.state.processing === true
                            ?
                            <Button
                                style={{ marginTop: 10 }}
                                variant="contained"
                                color="secondary"
                                className="save button-general"
                            >
                                <CircularProgress size={20} />
                            </Button>
                            :
                            <Button variant="contained" color="secondary" className="save button-general" onClick={() => this.dissociate()}>
                                Dissociate
                            </Button>
                    }
                </div>
            </div>
        </div>;
    }
}
