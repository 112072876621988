import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { APIResource } from '../../../Services/APIResource/APIResource';
import { SubdimensionContent } from './SubDimensionContent';
import Alert from '../../../Services/Alert';
import { scrollToRef, scrollToTop } from '../../../Services/utils';
import { getParamByIri } from '../../../Store/ParameterStore';
import MRA from '../../../Services/MRA';

export const DimensionContent = (props) => {
    const {
        dimension,
        setLoadingDimension,
        setOnlyReload,
        forceReload,
        addMraScore,
        getMraScores,
        mra,
        readonly,
    } = props;
    const { tabsRef } = props;

    const parentDimension = getParamByIri(dimension.dimension);
    const currentStatus = getParamByIri(mra.status);
    const currentProcess = getParamByIri(mra.process);

    const isNTXDimension = parentDimension.systemId && parentDimension.systemId === "MRA_DIMENSION_SOURCE_NTX"
    const isGROUPDimension = parentDimension.systemId && parentDimension.systemId === "MRA_DIMENSION_SOURCE_GROUP"
    const [subDimensions, setSubDimensions] = useState([]); // on cumule les subdimensions meme quand props.dimension change
    const [firstLoad, setFirstLoad] = useState(true);
    /** @type {[Object.<string, Array<number>>, () => {}]} Tableau de valeurs autorisées pour les scores pour chaque subdimensionIri */
    const [allowedScoreValues, setAllowedScoreValues] = useState(null);

    const subDimensionsResource = useMemo(
        () =>
            new APIResource({
                id: 'mra_subdimensions',
                name: 'Subdimensions',
            }),
        []
    );

    const onScoreChange = useCallback(addMraScore, []);
    const getMraScoresMemo = getMraScores; // useCallback(getMraScores, [scoresMap]);

    /**
     * Rechargement des sous-dimensions quand la dimension change.
     */
    useEffect(() => {
        setSubDimensions([]);
        loadSubDimensions(dimension, subDimensionsResource);
    }, [dimension, subDimensionsResource, forceReload]);

    /**
     * Re render des composants quand les sous-dimensions changent.
     */
    useEffect(() => {
        // Réactivation du bouton
        if (subDimensions && subDimensions.length) {
            setLoadingDimension(false);
        }
    }, [subDimensions]);

    useEffect(() => {
        MRA.getAllowedScoreValues(mra).then(setAllowedScoreValues)
    }, [mra]);

    const updateAllowedScoreValues = (mraScore) => {
        MRA.getAllowedScoreValuesFromMraScore(mraScore).then(allowedScoreValues =>
            setAllowedScoreValues(prev => ({...prev, ...allowedScoreValues}))
        );
    }

    const loadSubDimensions = (dimension, subDimensionsResource) => {
        setLoadingDimension(true);
        Promise.delay = function(t, val) {
            return new Promise((resolve) => {
                setTimeout(resolve.bind(null, val), t);
            });
        };
        if (dimension && dimension.mraSubdimensions) {
            let promises = dimension.mraSubdimensions.map((value) =>
                subDimensionsResource.getItemFromResourcePath(value)
            );
            /**
             * Si une des Promise ne resoud jamais, alors on resterait indéfiniment en attente.
             * Pour contrer cela on met une race avec une Promise "delay", et si la course est perdue
             * on ne met pas à jour les sous dimensions, et on active le bouton "reload".
             * (NB: la Promesse qui a perdu est quand même résolue !)
             * A l'appel suivant, le cache de ApiResource va accélerer les choses et la requête a des chances de
             * passer.
             */
            Promise.all(
                promises.map((p) => {
                    return Promise.race([p, Promise.delay(10000, 'timeout')]);
                })
            ).then((subDimensions) => {
                let subdimensions_ = subDimensions.filter(
                    (o) => o !== 'timeout'
                );
                if (subdimensions_.length !== subDimensions.length) {
                    Alert.show({
                        message:
                            'Subdimensions fetching took too long : aborting. Please reload.',
                        type: 'warning'
                    });
                    setLoadingDimension(false);
                    setOnlyReload(true);
                } else {
                    // Si on veut garder les anciennes sous-dimensions (comportement précédent) :
                    // Attention, cela ne marche plus car on vide les sous-dimensions au changement
                    // d'onglet actuellement, cf plus haut.
                    /*setSubDimensions((prev) => [
                        ...prev,
                        ...subDimensions,
                    ]); */
                    setSubDimensions(subDimensions);
                    setOnlyReload(false);
                    tabsRef && !firstLoad ? scrollToRef(tabsRef, "instant") : scrollToTop();
                    setFirstLoad(false);
                }
            });
        }
    };

    const genSubComponent = (subDimensions, onScoreChange, getMraScores) => {
        let subComponents = subDimensions.map((subdimension) => {
            return (
                <SubdimensionContent
                    key={subdimension.id}
                    subDimension={subdimension}
                    onScoreChange={(mraScore, remediationChanged) => {
                        if (remediationChanged) updateAllowedScoreValues(mraScore);
                        onScoreChange(mraScore);
                    }}
                    isNTXDimension={isNTXDimension}
                    isGROUPDimension={isGROUPDimension}
                    mraStatus={currentStatus}
                    mraProcess={currentProcess}
                    mraScoresToEdit={getMraScores(subdimension, true)}
                    mraScoresToShow={getMraScores(subdimension, false)}
                    readonly={readonly}
                    allowedScoreValues={allowedScoreValues?.[subdimension?.['@id']]}
                    isMraImported={mra.insertionFromImport}
                />
            );
        });
        return subComponents;
    };

    return (
        <div style={styles.container}>
            <div className="sub-dimension">{genSubComponent(subDimensions, onScoreChange, getMraScoresMemo)}</div>
        </div>
    );
};

DimensionContent.propTypes = {
    /** */
    dimension: PropTypes.object,
    setLoadingDimension: PropTypes.func,
    setOnlyReload: PropTypes.func,
    forceReload: PropTypes.bool,
    /** */
    addMraScore: PropTypes.func,
    getMraScores: PropTypes.func,
    /** Objet ressource Mra */
    mra: PropTypes.object,
    /** Si présent on scrolle jusqu'à la ref au lieu du top */
    tabsRef: PropTypes.any,
    /** Empeche la modification pour donner une vue en lecture */
    readonly: PropTypes.bool,
};

let styles = {
    container: {
        marginBottom: 60,
    },
};
